import enIcon from '../assets/eng-language.svg';
import trIcon from '../assets/tr-language.svg';

export const LANGUAGES = [
    { id: "1", name: 'English', value: 'en', flag: enIcon },
    { id: "2", name: 'Turkish', value: 'tr', flag: trIcon },
];

export const filterData = [
    { value: 1, title: "Popüler" },
    { value: 2, title: "Düşükten Pahalıya" },
    { value: 3, title: "Pahalıdan Düşüğe" },
    { value: 4, title: "En Yakın Tarih" },
    { value: 5, title: "En Uzak Tarih" },
]

export const GBP = '\u00A3'; // GBP (£)
export const EURO = '\u20AC';  // Euro (€)
export const TL = '\u20BA'; // Turkish Lira (₺)
export const USD = '\u0024'; // US Dollar ($)


export const WHATSAPP_NUMBER = "905425391924"
export const WHATSAPP_MESSAGE = 'hakkında detaylı bilgi alabilir miyim?';


// export const whatsapp_demo = `https://wa.me/${WHATSAPP_NUMBER}?text=${encodeURIComponent(`${tourParsedData?.title} ${WHATSAPP_MESSAGE}`)}`;
