import { all } from 'redux-saga/effects';
import commonSaga from '../pages/redux/saga';
import tourSaga from '../pages/content/tours/redux/saga';
import categorySaga from '../pages/content/category/redux/saga';
import subCategorySaga from '../pages/content/subCategory/redux/saga';
import bannerSaga from '../pages/content/banner/redux/saga';

export function* mainSaga() {
    //all application sagas here
    yield all([
        commonSaga,
        tourSaga,
        categorySaga,
        subCategorySaga,
        bannerSaga
    ]);
}
