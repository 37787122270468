import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Checkbox, CheckboxGroup, Col, Container, Divider, Dropdown, Form, Grid, Icon, IconButton, Input, List, Panel, Rate, Row, SelectPicker, Tag, TagGroup, Text, Timeline, Tooltip, Whisper } from 'rsuite';
import { GoogleMap, LoadScript, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import FormGroup from 'rsuite/esm/FormGroup';
import FormControl from 'rsuite/esm/FormControl';
import FormControlLabel from 'rsuite/esm/FormControlLabel';
import { getDateFormat, getPriceType } from '../utils/helpers';
import ArrowRightIcon from '@rsuite/icons/ArrowRight';
import { FaEuroSign } from "react-icons/fa6";
import logo from '../assets/logo-background.png';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import ArrowUpIcon from '@rsuite/icons/ArrowUp';
import { Link, useLocation, useNavigate, useParams, } from 'react-router-dom';
import italy from "../assets/carousel/italya.png";
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from "react-icons/fa";
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import i18n from "i18next";

export const TimelineItemIcon = ({ value }) => {
    return (
        <Grid style={{
            width: "50px", height: "50px", backgroundColor: 'rgb(17, 91, 185)',
            borderRadius: '50px', display: 'flex', alignItems: 'center',
            justifyContent: 'center'
        }}>
            {value}
        </Grid>
    );
}


export const NavMenu = ({ title, handleClick, eventKey, items, icon }) => {
    return (
        <Dropdown title={title} onSelect={() => handleClick(eventKey)} trigger={['click', 'hover']} icon={icon}>
            <div className='main_appbar_drowpdown'>
                {Array.isArray(items) ? items?.map((val, index) => (
                    <React.Fragment key={index}>
                        {val?.subCategories?.length > 0 ? (
                            <Dropdown.Menu title={val?.title ?? ""} icon={icon} style={{ padding: '5px' }}>
                                {val.subCategories?.map((el, key) => (
                                    <Dropdown.Item className='main_appbar_drowpdown_item' key={key} eventKey={el?.title} onSelect={() => handleClick(`${eventKey}/${val?.title}/${el?.title}`)}>
                                        <Link to={`${eventKey}/${val?.title}/${el?.title}`}>{el?.title ?? ""}</Link>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        ) : (
                            <Dropdown.Item icon={icon} className='main_appbar_drowpdown_item' eventKey={eventKey} onSelect={() => handleClick(`${eventKey}/${val?.title}`)}>
                                <Link to={`${eventKey}/${val?.title}`}>{val?.title ?? ""}</Link>
                            </Dropdown.Item>
                        )
                        }
                    </React.Fragment>
                )) : <p style={{ color: "#000" }}>{items}</p>}
            </div>
        </Dropdown>
    );
}


export const CustomText = ({ className, value, weight, style, ...props }) => {
    return (
        <Text className={className} weight={weight} style={{ ...style }} {...props}>{value}</Text>
    );
}

export const CustomBreadcrumb = ({ items, style }) => {
    return (
        <Breadcrumb style={{ ...style }}>
            <Link to="/">{i18n.t('common.main_page')}</Link>
            {items?.map((el, key) => (
                <Link key={key} to={el?.to}>{el?.name}</Link>
            ))}
        </Breadcrumb>
    )
}

export const CustomTextField = ({
    label, accepter, name, autoFocus, placeholder, data, iconOnClick, icon, titleClassname,
    errorMessage, errorPlacement, ...props
}) => {
    return (
        <FormGroup autoFocus={autoFocus}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {label && <FormControlLabel className={titleClassname ?? 'form-group_lbl'}>{label}</FormControlLabel>}
                {iconOnClick && <IconButton icon={icon} onClick={iconOnClick} appearance="subtle" />}
            </div>
            <FormControl name={name} accepter={accepter}
                placeholder={placeholder}
                errorMessage={errorMessage}
                errorPlacement={errorPlacement}
                data={data}
                {...props}
            />
        </FormGroup>
    );
}

export const CustomInput = ({
    title, titleClassName, titleStyle, required, autoFocus, error, children, inputWidth
}) => {
    return (

        <FormGroup autoFocus={autoFocus} style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: inputWidth ?? '100%' }}>
            {title && <span className={titleClassName ?? "search_lbl"} style={{ ...titleStyle }}>{title} {required &&
                <span style={{ color: 'red' }}> &nbsp;*</span>}</span>}
            {children}
            <Form.ErrorMessage show={!!error} placement="bottomStart">
                {error}
            </Form.ErrorMessage>
        </FormGroup>
    );
}


export const CustomListCardItem = ({ val, ...props }) => {

    const [pricing, setPricing] = useState(false);
    const [visitCountries, setVisitCountries] = useState([]);
    const isLogged = useSelector(state => state.commonReducer.isLogged);
    const user = useSelector(state => state.commonReducer.user);

    const navigate = useNavigate();


    useEffect(() => {
        if (val) {
            // Get today's date
            const today = new Date();
            // Filter tour dates that are after today and sort them to find the nearest one
            const nearestTourDate = val.tourDates
                .filter(tourDate => new Date(tourDate.start_date) >= today)
                .sort((a, b) => new Date(a.start_date) - new Date(b.start_date))[0]; // Get the nearest one

            // If a nearest tour date is found, set the pricing from that date's datePrices
            if (nearestTourDate && nearestTourDate?.datePrices?.length > 0) {
                let datePrice = nearestTourDate.datePrices[0];
                let totalAmount = datePrice.adult_price; // Start with the base adult price
                // Check if the user is logged in
                if (isLogged && user) {
                    // If user is logged in, apply the to_members_discount
                    if (Boolean(datePrice.to_members_discount)) {
                        totalAmount -= (datePrice.adult_price * datePrice.to_members_discount) / 100;
                    }
                } else {
                    // If user is not logged in, apply the regular discount
                    if (Boolean(datePrice.discount)) {
                        totalAmount -= (datePrice.adult_price * datePrice.discount) / 100;
                    }
                }

                setPricing({ ...datePrice, nearestTourDate: nearestTourDate, total_amount: datePrice.adult_price === totalAmount ? 0 : totalAmount });
            }

            setVisitCountries(val?.countries_to_visit?.split("->") ?? []);
        }

        return () => {
            setPricing(false);
            setVisitCountries([]);
        }
    }, [val])


    const getFirstWord = useCallback((value) => {
        if (value?.includes(" ")) {
            return value.substring(0, value.indexOf(" "));
        } else {
            return value;
        }
    }, [])


    const getToDetaiHandler = (value) => {
        if (value) {
            navigate(`detail/${val?.title}/${val?.code}`);
        }
    }
    return (
        <Container className='tour_item_card'>
            <Row className='tour_item_card_cnt'>
                <Col xl={9} md={9} sm={24} xs={24}>
                    <img alt='card_thumbnail' className='card_image' src={val?.thumbnail ?? ""} />
                </Col>
                <Col xl={15} md={15} sm={24} xs={24}>
                    {/* title */}
                    <Col xl={18} md={18} xs={16} className='tour_item_card_cnt_ttl'>
                        {/* title */}
                        <CustomText value={val?.title} style={{ fontSize: '17px' }} weight={"extrabold"} />
                        {/* visit countries */}
                        <Row style={{ marginLeft: 0 }}>
                            {visitCountries?.slice(0, 11)?.map((it, ind) => (
                                <Col key={ind} xl={24 / Math.min(visitCountries.length, 12)} style={{ display: 'flex', flexDirection: 'column', gap: '3px', padding: 0, marginBottom: '8px', width: "fit-content" }}>
                                    <div key={ind} style={{ display: 'flex', alignItems: 'center', position: 'relative', height: '10px', width: '100%' }}>
                                        <div style={{
                                            width: '10px', height: '10px', borderRadius: '50px',
                                            backgroundColor: "#2a5ab3", position: 'absolute',
                                            left: 1, zIndex: 9
                                        }} />
                                        <div style={{ minWidth: '100%', border: '1.5px solid #e8e8e8', display: ind === visitCountries.length - 1 && "none" }} />
                                    </div>
                                    <span style={{ wordWrap: 'break-word', whiteSpace: 'normal', fontSize: '11px', letterSpacing: '-0.02em', paddingRight: '10px' }}>{getFirstWord(it)}</span>
                                </Col>
                            ))}
                            {/* Display three dots if there are more than 12 items */}
                            {visitCountries?.length > 11 && (
                                <Col key="dots" xl={24 / 12} style={{ display: 'flex', padding: 0, width: "fit-content", textAlign: 'start' }}>
                                    <span style={{ fontSize: '17px', fontWeight: 700 }}>...</span>
                                </Col>
                            )}
                        </Row>
                        {/* tour dates */}
                        {val?.tourDates?.length > 0 &&
                            <Whisper placement="bottom" controlId="control-id-focus" trigger="focus"
                                speaker={
                                    <Tooltip style={{ minWidth: 'fit-content' }}>
                                        <Row style={{
                                            display: 'flex', flexDirection: 'column', padding: '6px',
                                            gap: '5px', width: 'fit-content'
                                        }}>
                                            {val.tourDates.map((item, ind) => (
                                                <Button appearance="primary" key={ind}
                                                    className='tour_item_card_cnt_ttl_calendar_btn' onClick={() => navigate(`detail/${val?.title}`)}>{`${getDateFormat(item.start_date)} - ${getDateFormat(item.end_date)}`}</Button>
                                            ))}
                                        </Row>
                                    </Tooltip>
                                }>
                                <Button appearance="primary"
                                    startIcon={<FaCalendarAlt />}
                                    className='tour_item_card_cnt_ttl_calendar_btn'
                                >{`${getDateFormat(pricing?.nearestTourDate?.start_date ?? val?.tourDates[0].start_date)} - ${getDateFormat(pricing?.nearestTourDate?.end_date ?? val?.tourDates[0].end_date)}`}</Button>
                            </Whisper>
                        }
                        {/* days and nights */}
                        {(val?.days || val?.nights) &&
                            <Tag style={{ width: 'fit-content' }}>{`${val?.nights ?? ""} Gece - ${val?.days ?? ""} Gün`}</Tag>
                        }

                    </Col>
                    {/* price */}
                    <Col xl={6} md={6} xs={8} className='tour_item_card_cnt_price'>
                        {Boolean(pricing?.adult_price) && (
                            <div className='tour_item_card_cnt_price_total'>
                                <CustomText value={i18n.t("tours.adult_price")} style={{ fontSize: '13px' }} weight={"medium"} />
                                <CustomText value={`${pricing?.adult_price} ${getPriceType(pricing?.currency)}`} />
                            </div>
                        )}
                        {Boolean(pricing?.child_price) && (
                            <div className='tour_item_card_cnt_price_pp'>
                                <CustomText value={i18n.t("tours.child_price")} style={{ fontSize: '13px' }} weight={"medium"} />
                                <CustomText value={`${pricing?.child_price} ${getPriceType(pricing?.currency)}`} />
                            </div>
                        )}
                        {Boolean(pricing?.single_accommodation) && (
                            <div className='tour_item_card_cnt_price_pp'>
                                <CustomText value={i18n.t("tours.single_accommodation")} style={{ fontSize: '13px' }} weight={"medium"} />
                                <CustomText value={`${pricing?.single_accommodation} ${getPriceType(pricing?.currency)}`} />
                            </div>
                        )}

                        {!isLogged && !user && Boolean(pricing?.discount) && (
                            <div>
                                <CustomText value={i18n.t("tours.discount")} style={{ fontSize: '11px' }} weight={"medium"} />
                                <CustomText value={`${pricing?.discount} %`} />
                            </div>
                        )}
                        {isLogged && user && Boolean(pricing?.to_members_discount) && (
                            <div>
                                <CustomText value={i18n.t("tours.to_members_discount")} style={{ fontSize: '11px' }} weight={"medium"} />
                                <CustomText value={`${pricing?.to_members_discount} %`} />
                            </div>
                        )}
                    </Col>
                </Col>
            </Row>
            <Row className='tour_item_card_btn'>
                {/* <div>
                    <img alt='icon' src={val.thumbnail} style={{ width: 'fit-content', height: '50px' }} />
                    <CustomText value={"Öneriyor"} style={{ fontSize: '17px', letterSpacing: '-0.02em', color: '#f18b00' }} weight={"extrabold"} />
                </div> */}
                {/* sub_title */}
                <div className='tour_item_card_btn_left'>
                    {val?.sub_title && (
                        <>
                            <ArrowRightIcon />
                            <CustomText value={val?.sub_title} style={{ fontSize: '17px', opacity: '.7', color: '#115bb9' }} weight={"extrabold"} />
                        </>
                    )}
                </div>
                <div className='tour_item_card_btn_right'>
                    <Button onClick={() => getToDetaiHandler(val)}>
                        {i18n.t("common.go_to_detail")}
                    </Button>
                </div>
            </Row>
        </Container>
    )
}

export const CustomCard = ({ image, header, linkTo, items }) => {
    return (
        <div className='zoom-card'>
            <Link to={linkTo} className='zoom-card-link'>
                <div className='zoom-card-image'>
                    <img src={image} alt={header} />
                </div>
                <div className='zoom-card-content'>
                    <h3>{header}</h3>
                </div>
            </Link>
            {items?.length > 0 && (
                <div className='zoom-card-list'>
                    <ul>
                        {items?.map((val, key) => (
                            <Link key={key} to={`${linkTo}/${val?.title}`} className='zoom-card-list-item'>
                                <li>{val?.title ?? ""}</li>
                            </Link>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export const FilterForm = ({ moreHandler, showMore, tourStartDates,
    tourTimes, tourStartCity, tourTranspType, rateble }) => {

    const [rate, setRate] = useState(1);

    const rateHandler = (value) => {
        setRate(value);
    }
    return (
        <Form layout="horizontal">
            {/* rating */}
            {rateble && (
                <FormGroup className='content_list_form_group'>
                    <FormControlLabel className='form-group_lbl'>{"Müşteri Puanı"}</FormControlLabel>
                    <div>
                        <Rate value={rate} size="md" onChange={(event) => rateHandler(event)} allowHalf />
                    </div>
                </FormGroup>
            )}
            {/* price */}
            <FormGroup className='content_list_form_group'>
                <FormControlLabel className='form-group_lbl'>{"Fiyat"}</FormControlLabel>
                <FormControl name={"az"} placeholder={"En az"} type='number' />
                <FormControl name={"çok"} placeholder={"En çok"} type='number' />
            </FormGroup>
            {/* Start dates */}
            <CustomTextField accepter={CheckboxGroup} label={"Tur Kalkiş Tarihleri"}
                name={"kalkış-tarihleri"}
                icon={showMore ? <ArrowDownIcon /> : <ArrowUpIcon />}
                iconOnClick={moreHandler}
            >
                {tourStartDates?.map((val, ind) => (
                    <Checkbox value={val.name} key={ind}>{val.name}</Checkbox>
                ))}
            </CustomTextField>

            {/* duration */}
            <CustomTextField accepter={CheckboxGroup} label={"Tur Süresi"} name={"süre"}>
                <Checkbox value={"Farketmez"}>{"Farketmez"}</Checkbox>
                {tourTimes?.length > 0 && tourTimes?.map((val, ind) => (
                    <Checkbox value={val} key={ind}>{`${val} Gece`}</Checkbox>
                ))}
            </CustomTextField>

            {/* Start place */}
            <CustomTextField accepter={CheckboxGroup} label={"Tur Kalkiş yeri"} name={"kalkış"}>
                {tourStartCity?.length > 0 && tourStartCity?.map((val, ind) => (
                    <Checkbox value={val} key={ind}>{val}</Checkbox>
                ))}
            </CustomTextField>


            <CustomTextField accepter={CheckboxGroup} label={"Ulaşım şekli"} name={"ulaşım"}>
                {tourTranspType?.length > 0 && tourTranspType?.map((val, ind) => (
                    <Checkbox value={val} key={ind}>{val}</Checkbox>
                ))}
            </CustomTextField>
        </Form>
    )
}


export const SwalInfoPopap = ({ position = "top-end", icon = "success",
    title = "Belirtilmedi", html,
    showConfirmButton = false, timer = 2500, text,
    thenFunc
}) => {
    return (
        Swal.fire({
            position: position,
            icon: icon,
            title: title,
            showConfirmButton: showConfirmButton,
            timer: timer,
            text: text,
            html: html,
        }).then((result) => {
            if (thenFunc) {
                thenFunc(result)
            }
        })
    )
}


/************************************** IMPLEMENT ON FUTURE *********************************** */
export const MapWithRoute = ({ cities }) => {
    const [directionsResponse, setDirectionsResponse] = useState(null);

    const calculateRoute = () => {
        if (cities.length < 2) return;

        const directionsService = new window.google.maps.DirectionsService();

        const waypoints = cities.slice(1, -1).map(city => ({
            location: city,
            stopover: true,
        }));

        directionsService.route(
            {
                origin: cities[0],
                destination: cities[cities.length - 1],
                waypoints: waypoints,
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirectionsResponse(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    };

    useEffect(() => {
        calculateRoute();
    }, [cities]);

    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '400px' }}
                zoom={6}
                center={{ lat: 41.9028, lng: 12.4964 }} // Center around Rome initially
            >
                {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                )}
            </GoogleMap>
        </LoadScript>
    );
};