import React from 'react'
import { Button, Col, Container, Grid, Heading, IconButton, Row } from 'rsuite'
import './footer_case.scss';
import { CustomText } from '../CustomComponents';
import { Link } from 'react-router-dom';
import { FaTelegram } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import visa from '../../assets/footer/visa.png';
import mastercard from '../../assets/footer/card.png';
import tursab from '../../assets/footer/TURSAB-RGB.png';
import PhoneFillIcon from '@rsuite/icons/PhoneFill';


const Footer = (props) => {
    return (
        <Grid fluid className='footer'>
            <Row className='footer_row'>
                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Heading level={6}>{"Hakkımızda"}</Heading>
                    <Link to="/about"><CustomText value={"Hakkımızda"} weight={"bold"} /></Link>
                    <Link to="/kvkk"><CustomText value={"KVKK"} weight={"bold"} /></Link>
                    <Link to="/privacy-police"> <CustomText value={"Gizlilik Politikası"} weight={"bold"} /></Link>
                    {/* <Link to="/about"><CustomText value={"Hizmet Kalitemiz"} weight={"bold"} /></Link> */}
                    {/* <Link to="/contact"><CustomText value={"Kurumsal iletişim"} weight={"bold"} /></Link> */}
                </Col>

                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Heading level={6}>{"Şartlar"}</Heading>
                    <Link to="#"><CustomText value={"Kullanıcı Sözleşmesi"} weight={"bold"} /></Link>
                    <Link to="#"><CustomText value={"Gizlilik Sözleşmesi"} weight={"bold"} /></Link>
                    <Link to="#"> <CustomText value={"Tur/Otel Satış Sözleşmesi"} weight={"bold"} /></Link>
                    <Link to="#"><CustomText value={"Kişisel Verilerin Korunması Kanunu"} weight={"bold"} /></Link>
                </Col>
                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Heading level={6}>{"Takip"}</Heading>
                    <Link to="https://t.me/hamza_95_T" target='_blank'><IconButton icon={<FaTelegram />} /></Link>
                    <Link to="https://www.instagram.com/yesil_pasaportlular/" target='_blank'><IconButton icon={<RiInstagramFill />} /></Link>
                    <Link to="https://www.facebook.com/" target='_blank'><IconButton icon={<FaFacebook />} /></Link>
                </Col>
                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Heading level={6}>{"İletişim"}</Heading>
                    <Link to="/contact"><CustomText value={"İletişim Bilgileri"} weight={"bold"} /></Link>

                </Col>

            </Row>
            <Row className='footer_row'>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>ADALI TURİZM</span>
                        <small>Belge No: A 958</small>
                    </div>

                </Col>
                <Col>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <img alt='' src={tursab} style={{ height: '40px', maxWidth: '200px' }} />
                        <img alt='' src={mastercard} style={{ height: '40px', maxWidth: '200px' }} />
                        <img alt='' src={visa} style={{ height: '40px', maxWidth: '200px' }} />
                    </div>
                </Col>

            </Row>
            <Row className='footer_row'>
                <span>Çağrı Merkez:</span>
                <Button as={Link} to={"tel:+90 212 674 44 30"} startIcon={<PhoneFillIcon />}>0 212 674 44 30</Button>
            </Row>
        </Grid>
    )
}

export default Footer