import { combineReducers } from 'redux';
import commonReducer from '../pages/redux/reducer';
import tourReducer from '../pages/content/tours/redux/reducer';
import categoryReducer from '../pages/content/category/redux/reducer';
import subCategoryReducer from '../pages/content/subCategory/redux/reducer';
import bannerReducer from '../pages/content/banner/redux/reducer';

//all application reducers here
const reduc = combineReducers({
    commonReducer,
    tourReducer,
    categoryReducer,
    subCategoryReducer,
    bannerReducer
  });
  
  export const combinedReducers = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
      state = undefined;
    }
    return reduc(state, action);
  };
  