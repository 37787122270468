import { COMMON_VALIDATION_ERROR, COMMON_ERROR, COMMON_REQUEST, COMMON_SUCCESS, LOGIN, USER_DATA, LOGOUT, LOGOUT_SUCCESS, IS_DARK_MODE, LIST_DATA, BOOK_DATA, SIGNUP, SEARCH_DATA, EXCHANGE_RATES } from "./constants";

export const loginAction = (body, callback) => ({
    type: LOGIN,
    body,
    callback
});
export const signUpAction = (body) => ({
    type: SIGNUP,
    body
});
export const logoutAction = (token) => ({
    type: LOGOUT,
    token
});
export const logoutSuccessAction = () => ({
    type: LOGOUT_SUCCESS
});

export const userDataAction = (data) => ({
    type: USER_DATA,
    data
});

export const commonRequestAction = (data) => ({
    type: COMMON_REQUEST,
    data
});

export const commonErrorAction = (data) => ({
    type: COMMON_ERROR,
    data
});

export const commonSuccessAction = (successData) => ({
    type: COMMON_SUCCESS,
    successData
});

export const commonValidationErrorAction = (errorData) => ({
    type: COMMON_VALIDATION_ERROR,
    errorData
});

export const setDarkModeAction = (data) => ({
    type: IS_DARK_MODE,
    data,
});

export const setListData = (data) => ({
    type: LIST_DATA,
    data
});

export const setBookData = (data) => ({
    type: BOOK_DATA,
    data
});

export const setSearchData = (data) => ({
    type: SEARCH_DATA,
    data
});

export const setExchangeRates = (data) => ({
    type: EXCHANGE_RATES,
    data
});
