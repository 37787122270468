import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Grid, Row, Col, Form, Dropdown, InputNumber, Input, Nav, SelectPicker } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { DateRangePicker } from 'rsuite';
import { CustomInput, } from '../components/CustomComponents';
import './main_case.scss';
import { useTranslation } from 'react-i18next';
import { FaUserFriends } from "react-icons/fa";
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { searchTourAction } from './content/tours/redux/actions';
import { generateTourDates, getDateFormat, getMainPathname, parseMonthYear } from '../utils/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaHotel } from "react-icons/fa6";
import { MdTour } from "react-icons/md";
import { MdOutlineModeOfTravel } from "react-icons/md";
import { setListData, setSearchData } from './redux/actions';
const { beforeToday } = DateRangePicker;

const SearchBar = (props) => {

    const { t } = useTranslation();
    const {
        handleSubmit,
        control, reset, setValue, getValues,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const ref = useRef(true);
    const location = useLocation();
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [curTab, setCurTab] = useState("tours");
    const [curMonths, setCurMonths] = useState([]);
    const [mainUrl, setMainUrl] = useState();

    useEffect(() => {
        if (Boolean(location.pathname)) {
            setMainUrl(getMainPathname(location.pathname));
        }
    }, [location.pathname])

    useEffect(() => {
        if (mainUrl) {
            handleTabs(mainUrl)
        }
    }, [mainUrl])

    useEffect(() => {
        if (props.searchValue) {
            setValue("searchValue", props.searchValue);
        }
        if(props.date_range){
            setValue("date_range", props.date_range);
        }
    }, [])

    useEffect(() => {
        if (ref.current) {
            let months = generateTourDates().map(item => ({ label: item.name, value: item.name }))
            if (months?.length > 0) {
                setCurMonths(months);
                // setValue("date_range", months[1])
            }
            ref.current = false;
        }
    }, [ref])

    const onSubmit = (data) => {
        let searchValue = Boolean(data.searchValue?.length > 0) ? data.searchValue.split(' ') : null;
        let dateRange = "";

        if (curTab === "tours") {
            dateRange = Boolean(data.date_range) ? parseMonthYear(data.date_range) : "";
            props.searchTourAction({
                filter: {
                    SearchType: 0,
                    SearchValues: searchValue,
                    StartDate: dateRange?.length > 0 ? dateRange[0] : "",
                    EndDate: dateRange?.length > 0 ? dateRange[1] : "",
                    page: 1, limit: 10
                }
            }, submitCallback);
        } else {
            // dateRange = data.date_range;
            // props.searchTourAction({
            //     filter: {
            //         SearchType: 0,
            //         SearchValues: searchValue,
            //         StartDate: dateRange?.length > 0 ? getDateFormat(dateRange[0]) : "",
            //         EndDate: dateRange?.length > 0 ? getDateFormat(dateRange[1]) : "",
            //         page: 1, limit: 10
            //     }
            // }, submitCallback);
        }
    }

    const submitCallback = useCallback((data) => {
        if (data) {
            let values = getValues();
            props.setSearchData({ searchValue: values.searchValue, date_range: values.date_range, last_page: data.totalPages })
            props.setListData(data);
            navigate(`/searching/${Boolean(values.searchValue) ? values.searchValue : "All"}`);
        }
    }, [])

    const handleTabs = (key) => {
        if ((key === "tours" || key === "hotels") && curTab !== key) {
            setCurTab(key);
            setValue("date_range", key === "tours" ? "" : [new Date(), new Date(Date.now() + (3600 * 1000 * 24))]);
        }
    }

    return (
        <Grid className='main_search'>
            <Form className='main_search_form' fluid onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Nav appearance="subtle" justified defaultActiveKey={"tours"} activeKey={curTab} onSelect={handleTabs}>
                        <Nav.Item style={{ padding: '10px' }} icon={<FaHotel />} eventKey='hotels'>{t('nav_bar.hotel')} {t("nav_bar.search")}</Nav.Item>
                        <Nav.Item style={{ padding: '10px' }} icon={<MdTour />} eventKey='tours'>{t('nav_bar.tour')} {t("nav_bar.search")}</Nav.Item>
                    </Nav>
                </Row>
                <Row>
                    <Col md={24}>
                        <Controller
                            name="searchValue"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                                <CustomInput
                                    title={t('search_bar.where')}
                                    error={errors[field?.name]?.message}
                                    children={
                                        <Input value={field?.value} onChange={field?.onChange}
                                            placeholder={`${t('nav_bar.region')} & ${t('nav_bar.city')} & ${t('nav_bar.hotel')}`}
                                        />
                                    }
                                />
                            )}
                        />

                    </Col>
                    <Col md={24}>
                        {curTab === "hotels" && (
                            <Controller
                                name="date_range"
                                control={control}
                                defaultValue={[new Date(), new Date(Date.now() + (3600 * 1000 * 24))]}
                                render={({ field }) => (
                                    <CustomInput
                                        title={t('search_bar.how_many_time')}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <DateRangePicker
                                                style={{ width: '100%', borderRadius: '20px' }}
                                                placeholder={t("nav_bar.date_range")} shouldDisableDate={beforeToday()}
                                                value={field?.value} onChange={field?.onChange} />
                                        }
                                    />
                                )}
                            />
                        )}
                        {curTab === "tours" && (
                            <Controller
                                name="date_range"
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <CustomInput
                                        title={t('search_bar.when')}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <SelectPicker
                                                data={curMonths}
                                                style={{ zIndex: 100 }}
                                                value={field?.value}
                                                onChange={field?.onChange}
                                            />
                                        }
                                    />
                                )}
                            />
                        )}
                    </Col>
                    {curTab === "hotels" && (
                        <Col md={24}>
                            <Controller
                                name="people_count"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <CustomInput
                                        title={t('search_bar.how_many_people')}
                                        error={errors[field?.name]?.message}
                                        children={
                                            <Dropdown
                                                title={`${t('search_bar.adults')}: ${adults} - ${t('search_bar.children')}: ${children}`}
                                                icon={<FaUserFriends />}
                                                trigger="click"
                                                placement="bottomEnd"
                                                style={{ width: 'fit-content' }}
                                            >
                                                <Dropdown.Item panel>
                                                    <div style={{ padding: '8px 20px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', gap: '5px' }}>
                                                            <span>{t('search_bar.adults')}:</span>
                                                            <InputNumber
                                                                min={1}
                                                                value={adults}
                                                                onChange={value => setAdults(value)}
                                                                style={{ width: 60 }}
                                                            />
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '5px' }}>
                                                            <span>{t('search_bar.children')}:</span>
                                                            <InputNumber
                                                                min={0}
                                                                value={children}
                                                                onChange={value => setChildren(value)}
                                                                style={{ width: 60 }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown>
                                        }
                                    />
                                )}
                            />

                        </Col>
                    )}
                    <Col md={24} style={{ marginTop: '10px' }}>
                        <Button
                            type='submit'
                            appearance="primary"
                            startIcon={<SearchIcon />}
                            loading={props.common_requesting}
                            style={{ minWidth: '100%', backgroundColor: "#2a5ab3", '&:hover': { backgroundColor: '#84a7e7' } }}
                        >
                            {t("nav_bar.search")}
                        </Button>
                    </Col>
                </Row>

            </Form>
        </Grid >
    )
}

const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    searchValue: state.commonReducer.searchValue,
    date_range: state.commonReducer.date_range,
});

const mapDispatchToProps = dispatch => ({
    searchTourAction: (body, callback) => dispatch(searchTourAction(body, callback)),
    setListData: (data) => dispatch(setListData(data)),
    setSearchData: (data) => dispatch(setSearchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
