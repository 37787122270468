import { SEARCH_TOUR, GET_TOUR_BY_NAME, GET_TOUR_PRICES, GET_TOUR_FINAL_PRICE, GET_PAYMENT_OPTIONS, BOOK_TOUR, GET_TOURS,  GET_TOUR_BY_CODE, SET_TOUR_DATA } from "./constants";

export const searchTourAction = (body, callback) => ({
    type: SEARCH_TOUR,
    body,
    callback
});

export const getToursAction = (queryParams) => ({
    type: GET_TOURS,
    queryParams
});

export const getTourByNameAction = (name, callback) => ({
    type: GET_TOUR_BY_NAME,
    name,
    callback
});

export const getTourByCodeAction = (code, callback) => ({
    type: GET_TOUR_BY_CODE,
    code,
    callback
});


export const getTourPricesAction = (body, callback) => ({
    type: GET_TOUR_PRICES,
    body,
    callback
});

export const getTourFinalPriceAction = (body) => ({
    type: GET_TOUR_FINAL_PRICE,
    body
});

export const getPaymentOptionsAction = (body) => ({
    type: GET_PAYMENT_OPTIONS,
    body
});

export const bookTourAction = (body, callback) => ({
    type: BOOK_TOUR,
    body,
    callback
});

export const setTourDataAction = (data) => ({
    type: SET_TOUR_DATA,
    data
});