// src/pages/content/categories/redux/saga.js
import { call, put, all, takeLatest } from "redux-saga/effects";
import {
    getBySubCategoryName,
    getSubCategories,
} from "../../../../services/sub_category.service";
import {
    commonRequestAction,
    commonSuccessAction,
    commonErrorAction,
    commonValidationErrorAction,
} from "../../../redux/actions";
import { GET_BY_SUBCATEGORY_NAME, GET_SUB_CATEGORIES_BY_CATEGORY_ID } from "./constants";
import { setSubCategoryData } from "./actions";



function* getSubCategoriesByCategoryIdSaga({ category_id, callback }) {
    try {
        const response = yield call(getSubCategories, category_id);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
            if(callback) {
                callback(response.data?.data)
            }
        }
    } catch (error) {
        // yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

function* getBySubCategoryNameSaga({ params, callback }) {
    try {
        const response = yield call(getBySubCategoryName, params);
        if (response.status === 200) {
            yield put(commonSuccessAction(response.data.message));
            if (callback) {
                callback(response.data);
            }
        }
    } catch (error) {
        // yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}


export default all([
    takeLatest(GET_SUB_CATEGORIES_BY_CATEGORY_ID, getSubCategoriesByCategoryIdSaga),
    takeLatest(GET_BY_SUBCATEGORY_NAME, getBySubCategoryNameSaga),
]);