// src/pages/content/tours/redux/reducer.js
import {
    GET_TOUR_BY_CODE, SET_TOUR_DATA,
} from "./constants";

const initialState = {
    tours_data: null,
};

const tourReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOUR_DATA:
            return {
                ...state,
                tours_data: action.data,
            };
        default:
            return state;
    }
};

export default tourReducer;
