// src/pages/content/categories/redux/saga.js
import { call, put, all, takeLatest } from "redux-saga/effects";
import {
    getCategories,
} from "../../../../services/category.sevice";
import {
    commonRequestAction,
    commonSuccessAction,
    commonErrorAction,
    commonValidationErrorAction,
} from "../../../redux/actions";
import { GET_CATEGORIES } from "./constants";
import { setCategoryData } from "./actions";



function* getCategoriesSaga({ queryParams }) {
    try {
        const response = yield call(getCategories, queryParams);
        if (response.status === 200) {
            // yield put(commonSuccessAction(response.data.message));
            yield put(setCategoryData(response.data?.data));
        }
    } catch (error) {
        console.log("error", error?.response?.data?.message)
        // yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(GET_CATEGORIES, getCategoriesSaga),
]);