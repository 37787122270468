import React, { Suspense, lazy, useEffect, useLayoutEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loader } from 'rsuite';
import './main_case.scss';
import PublicRoutes from '../utils/PublicRoutes';
import PrivateRoutes from '../utils/PrivateRoutes';
import Main from './Main';
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import AuthProvider from '../utils/AuthProvider';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { commonErrorAction, setExchangeRates } from './redux/actions';
import { SwalInfoPopap } from '../components/CustomComponents';

const MainPage = lazy(() => import("./content/main/MainPage"));
const Category = lazy(() => import("../components/common/Category"));
const Detail = lazy(() => import("../components/common/Detail"));
const Listing = lazy(() => import("../components/common/Listing"));
const SubCategory = lazy(() => import("../components/common/SubCategory"));
const TourBoookingForm = lazy(() => import("../pages/content/tours/TourBoookingForm"));
const SSS = lazy(() => import("../pages/content/healthInsurance/SSS"));
const Contact = lazy(() => import("../pages/content/about/Contact"));
const About = lazy(() => import("../pages/content/about/About"));
const PrivacyPolice = lazy(() => import("../pages/content/about/PrivacyPolice"));
const KVKK = lazy(() => import("./content/about/KVKK"));

export const publicRoutes = [
    {
        path: '/',
        element: <MainPage />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },

    {
        path: '/searching/:searchValue',
        element: <Listing />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },

    /**
     * DETAIL FROM SEARCH BAR
     */
    {
        path: '/searching/:searchValue/detail/:name/:code',
        element: <Detail />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },




    /**
     * DETAIL FROM MAIN PAGE
     */
    {
        path: '/detail/:name/:code',
        element: <Detail />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },

    /**
     *  ITEM DETAIL
     */
    {
        path: '/tours/:category/:subcategory/detail/:name/:code',
        element: <Detail />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/hotels/:category/:subcategory/detail/:name/:code',
        element: <Detail />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/transfers/:category/:subcategory/detail/:name/:code',
        element: <Detail />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/education/:category/:subcategory/detail/:name/:code',
        element: <Detail />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },



    /**
     *  SUB CATEGORY ITEM LIST
     */
    {
        path: '/tours/:category/:subcategory',
        element: <Listing />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/hotels/:category/:subcategory',
        element: <Listing />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/transfers/:category/:subcategory',
        element: <Listing />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/education/:category/:subcategory',
        element: <Listing />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },



    /**
     *  SUB CATEGORY LIST
     */
    {
        path: '/tours/:category',
        element: <SubCategory />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/hotels/:category',
        element: <SubCategory />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/transfers/:category',
        element: <SubCategory />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/education/:category',
        element: <SubCategory />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },



    /**
     * CATEGORY LIST
     */
    {
        path: '/tours',
        element: <Category />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/hotels',
        element: <Category />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/transfers',
        element: <Category />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/education',
        element: <Category />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },

    {
        path: '/SSS',
        element: <SSS />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/contact',
        element: <Contact />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/privacy-police',
        element: <PrivacyPolice />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/about',
        element: <About />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
    {
        path: '/kvkk',
        element: <KVKK />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: false,
        isPrivate: false
    },
]

const privateRoutes = [
    /**
    * TourBoookingForm FROM DETAIL PAGE
    */
    {
        path: '/book/:code',
        element: <TourBoookingForm />,
        isDisabled: false,
        exact: true,
        isAuthNeeded: true,
        isPrivate: true
    },
]

const Gateway = (props) => {

    useEffect(() => {
        const fetchExchangeRates = async () => {
            const response = await fetch('https://api.exchangerate-api.com/v4/latest/TRY');
            const data = await response.json();
            props.setExchangeRates(data.rates);
        };

        fetchExchangeRates();
    }, [])

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message.startsWith('ResizeObserver loop')) {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    useEffect(() => {
        if (props.common_error) {
            SwalInfoPopap({
                position: "center", icon: "error",
                title: props.common_error?.message,
                text: `Hata kodu: ${props.common_error?.status}`,
                thenFunc: (result) => {
                    props.commonErrorAction(null);
                }
            })
        }
    }, [props.common_error]);

    return (
        <Suspense fallback={<Loader content="Loading..." vertical backdrop />}>
            <Routes>
                <Route element={<PublicRoutes />}>
                    <Route path="/" element={<Main />}>
                        {publicRoutes.filter(item => !item.isAuthNeeded).map((val, key) => (
                            <Route key={key} path={val?.path} element={val?.element} />
                        ))}
                    </Route>
                </Route>
                <Route element={<AuthProvider />}>
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/signup" element={<SignUp />} />
                </Route>
                <Route element={<PrivateRoutes />}>
                    {privateRoutes.filter(item => item.isAuthNeeded).map((val, key) => (
                        <Route key={key} path={val?.path} element={val?.element} />
                    ))}
                </Route>

                {/* <Route exact path="*" element={<Loader style={{ zIndex: 99999 }} backdrop content="Loading..." vertical />} /> */}
            </Routes>
        </Suspense>
    )
}
const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    common_error: state.commonReducer.common_error,
    exchange_rates: state.commonReducer.exchange_rates,
});

const mapDispatchToProps = dispatch => ({
    commonErrorAction: (data) => dispatch(commonErrorAction(data)),
    setExchangeRates: (data) => dispatch(setExchangeRates(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Gateway)