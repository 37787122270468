// src/pages/content/sub_categories/redux/reducer.js
import {
    SUB_CATEGORY_DATA,
    GET_SUB_CATEGORY_BY_CODE,
} from "./constants";

const initialState = {
    sub_categories_data: [],
};

const subCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        /**
         * @param SUB_CATEGORY_DATA - usage for updating sub_categories_data data in the state
         */
        case SUB_CATEGORY_DATA:
            return {
                ...state,
                sub_categories_data: action.data,
            };
        default:
            return state;
    }
};

export default subCategoryReducer;
