// src/pages/content/categories/redux/saga.js
import { call, put, all, takeLatest } from "redux-saga/effects";
import {
    getActiveBanner,
    getBanners,
} from "../../../../services/banner.service";
import {
    commonRequestAction,
    commonSuccessAction,
    commonErrorAction,
    commonValidationErrorAction,
} from "../../../redux/actions";
import { GET_ACTIVE_BANNER, GET_BANNERS } from "./constants";
import { setBannerData } from "./actions";



function* getBannersSaga({ queryParams }) {
    try {
        const response = yield call(getBanners, queryParams);
        if (response.status === 200) {
            // yield put(commonSuccessAction(response.data.message));
            yield put(setBannerData(response.data?.data));
        }
    } catch (error) {
        console.log("error", error?.response?.data?.message)
        // yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}


function* getActiveBannerSaga({ callback }) {
    try {
        const response = yield call(getActiveBanner);
        if (response.status === 200) {
            yield put(setBannerData(response.data?.data));
            if (callback) {
                callback(response.data?.data);
            }
        }
    } catch (error) {
        console.log("error", error?.response?.data?.message)
        // yield put(commonErrorAction({ status: error.response?.status, message: error.response?.data?.message }));
    } finally {
        yield put(commonRequestAction(false));
    }
}

export default all([
    takeLatest(GET_BANNERS, getBannersSaga),
    takeLatest(GET_ACTIVE_BANNER, getActiveBannerSaga),
]);