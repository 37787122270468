import React, { useState } from 'react'
import { signUpAction } from '../redux/actions';
import { Controller, useForm } from 'react-hook-form';
import { Button, ButtonToolbar, FlexboxGrid, Form, Input, InputGroup, Panel, Tooltip, Whisper } from 'rsuite';
import { CustomInput } from '../../components/CustomComponents';
import AvatarIcon from '@rsuite/icons/legacy/Avatar';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { connect } from 'react-redux';
import EmailFillIcon from '@rsuite/icons/EmailFill';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo-HD.png'
import { useNavigate } from 'react-router-dom';
import './login_case.scss';

const SignUp = (props) => {
    const {
        handleSubmit,
        control, watch, setValue, getValues,
        formState: { errors }
    } = useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
    const handlePasswordVisibility = () => {
        setVisiblePassword(!visiblePassword);
    };
    const handleConfirmPasswordVisibility = () => {
        setVisibleConfirmPassword(!visibleConfirmPassword);
    };

    const onSubmit = (data) => {
        const formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (key !== "confirmPassword" && value) {
                formData.append(key, value);
            }
        })


        props.signUpAction(formData);
    }


    return (
        <FlexboxGrid justify="center" className="auth">
            <FlexboxGrid.Item style={{ width: '100%', height: "200px", justifyContent: 'center', display: 'flex', cursor: "pointer" }} onClick={() => navigate("/")}>
                <img alt='yesilpasaportlular' src={logo} style={{ maxHeight: '100%', maxWidth: '100%' }} />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={10} style={{ width: '100%' }}>
                <Panel header={<strong style={{ fontSize: '32px' }}>SignUp</strong>} bordered>
                    <Form fluid onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="fullName"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                                <CustomInput
                                    title={"Isim Soyisim"}
                                    required
                                    error={errors[field?.name]?.message}
                                    children={
                                        <Whisper trigger="focus" placement='topStart' speaker={<Tooltip>Gerekli</Tooltip>}>
                                            <InputGroup inside>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                                <InputGroup.Addon>
                                                    <UserBadgeIcon />
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        </Whisper>
                                    }
                                />
                            )}
                            rules={{ required: t('common.required') }}
                        />
                        <Controller
                            name="username"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                                <CustomInput
                                    title={"Kullanıcı adı"}
                                    required
                                    error={errors[field?.name]?.message}
                                    children={
                                        <Whisper trigger="focus" placement='topStart' speaker={<Tooltip>Gerekli</Tooltip>}>
                                            <InputGroup inside>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                                <InputGroup.Addon>
                                                    <AvatarIcon />
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        </Whisper>
                                    }
                                />
                            )}
                            rules={{ required: t('common.required') }}
                        />
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                                <CustomInput
                                    title={"E-posta"}
                                    required
                                    error={errors[field?.name]?.message}
                                    children={
                                        <Whisper trigger="focus" placement='topStart' speaker={<Tooltip>Gerekli</Tooltip>}>
                                            <InputGroup inside>
                                                <Input value={field?.value} onChange={field?.onChange} />
                                                <InputGroup.Addon>
                                                    <EmailFillIcon />
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        </Whisper>
                                    }
                                />
                            )}
                            rules={{ required: t('common.required') }}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                                <CustomInput
                                    title={"Şifre"}
                                    required
                                    error={errors[field?.name]?.message}
                                    children={
                                        <Whisper trigger="focus" placement='topStart' speaker={<Tooltip>Gerekli</Tooltip>}>
                                            <InputGroup inside>
                                                <Input type={visiblePassword ? 'text' : 'password'} value={field?.value} onChange={field?.onChange} />
                                                <InputGroup.Button onClick={handlePasswordVisibility}>
                                                    {visiblePassword ? <EyeIcon /> : <EyeSlashIcon />}
                                                </InputGroup.Button>
                                            </InputGroup>
                                        </Whisper>
                                    }
                                />
                            )}
                            rules={{ required: t('common.required') }}
                        />
                        <Controller
                            name="confirmPassword"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                                <CustomInput
                                    title={"Şifreyi Onayla"}
                                    required
                                    error={errors[field?.name]?.message}
                                    children={
                                        <Whisper trigger="focus" placement='topStart' speaker={<Tooltip>Gerekli</Tooltip>}>
                                            <InputGroup inside>
                                                <Input type={visibleConfirmPassword ? 'text' : 'password'} value={field?.value} onChange={field?.onChange} />
                                                <InputGroup.Button onClick={handleConfirmPasswordVisibility}>
                                                    {visibleConfirmPassword ? <EyeIcon /> : <EyeSlashIcon />}
                                                </InputGroup.Button>
                                            </InputGroup>
                                        </Whisper>
                                    }
                                />
                            )}
                            rules={{ required: t('common.required'), validate: value => value === watch('password') || t('common.not_match_pwd') }}
                        />
                        <Form.Group>
                            <ButtonToolbar>
                                <Button appearance="primary" type='submit'>Üye ol</Button>
                                <Button appearance="link" href={'/login'}>Giriş yap</Button>
                            </ButtonToolbar>
                        </Form.Group>
                    </Form>
                </Panel>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}
const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
});

const mapDispatchToProps = dispatch => ({
    signUpAction: (body) => dispatch(signUpAction(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)