import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Carousel, Loader, IconButton, } from 'rsuite';
import './main_case.scss';
import SearchBar from './SearchBar';
import Gateway, { publicRoutes } from './Gateway';
import NavBar from '../components/navbar/NavBar';
import Footer from '../components/footer/Footer';
import ArrowUpIcon from '@rsuite/icons/ArrowUp';
import { LANGUAGES } from '../utils/constants';
import { getStorageItem, setStorageItem } from '../utils/helpers';
import { Route, Routes, useLocation } from 'react-router-dom';
import { getCategoriesAction } from './content/category/redux/actions';
import { connect } from 'react-redux';
import { getActiveBannerAction } from './content/banner/redux/actions';
import { useTranslation } from 'react-i18next';


function Main(props) {
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [bannerData, setBannerData] = useState(false);
    const isLangInitialized = getStorageItem("lang");
    const location = useLocation();
    const { t } = useTranslation();

    if (!isLangInitialized) {
        setStorageItem("lang", LANGUAGES[1]);
    }

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };

    const scrollToTop = () => {
        const navbarElement = document.getElementById('scroll_to');
        if (navbarElement) {
            navbarElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isCarouselDisplay = useCallback(() => {
        const listOfPathnames = ["/detail/", "/book/", "/SSS", "/about", "/contact", "/privacy-police", "/kvkk"];

        // Check if the current pathname starts with any of the specified values
        const isExcludedPath = listOfPathnames.some(path => location?.pathname.includes(path));

        return !isExcludedPath;
    }, [location]);

    // New effect to scroll to main-content on URL change
    useEffect(() => {
        const scrollToMainContent = () => {
            const mainContent = document.getElementById('main-content');
            if (mainContent) {
                mainContent.scrollIntoView({ behavior: 'smooth' });
            }
        };

        const pathsToScroll = [
            /\/tours\/.+/,
            /\/searching\/.+/,
            /\/hotels\/.+/,
            /\/transfers\/.+/,
        ];

        // Check if the current pathname matches any of the specified patterns
        if (pathsToScroll.some(pattern => pattern.test(location.pathname))) {
            scrollToMainContent();
        }
    }, [location]);

    useEffect(() => {
        props.getActiveBannerAction(callback);
    }, []);

    const callback = (data) => {
        if (data) {
            const parsedImages = data?.images?.split("->") ?? [];
            setBannerData({ ...data, images: parsedImages })
        }
    }

    return (
        <div className='main'>
            <NavBar idForScroll={!isCarouselDisplay() ? "scroll_to" : undefined} />
            <Container>
                {isCarouselDisplay() && (
                    <Container className='main_ttl'>
                        <Carousel autoplay shape={bannerData?.shape || "bar"} className="custom-slider" id="scroll_to">
                            {bannerData?.images?.length > 0 && bannerData.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    style={{ objectFit: 'cover', maxHeight: '100%', maxWidth: '100%' }}
                                />
                            ))}
                        </Carousel>
                        {bannerData?.title &&
                            <div className="banner-overlay">
                                <div className="banner-content">
                                    <h2>{bannerData.title}</h2>
                                    <p>{bannerData?.description}</p>
                                    {bannerData?.link_to && (
                                        <a href={bannerData.link_to} target='_blank' className="banner-button">{t('common.review_now')}</a>
                                    )}
                                </div>
                            </div>
                        }

                        <SearchBar />
                    </Container>
                )}
                <Container className='main_lst' id='main-content'>
                    <div className='content'>
                        <Routes>
                            {publicRoutes.filter(item => !item.isAuthNeeded)
                                .map((val, key) => <Route key={key} exact path={val?.path} element={val?.element} />)
                            }
                        </Routes>
                    </div>
                </Container>
            </Container>
            <Container className='main_footer'>
                <div className='content'>
                    <Footer />
                </div>
            </Container>
            {showScrollButton && (
                <IconButton icon={<ArrowUpIcon />} className="scroll-to-top" onClick={scrollToTop} />
            )}
        </div >
    );
}
const mapStateToProps = state => ({
    common_requesting: state.commonReducer.common_requesting,
    common_error: state.commonReducer.common_error,
    tours_data: state.tourReducer.tours_data,
    banner_data: state.bannerReducer.banner_data
});

const mapDispatchToProps = dispatch => ({
    getActiveBannerAction: (callback) => dispatch(getActiveBannerAction(callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
