import { BOOK_TOUR, GET_TOURS, GET_TOUR_BY_CODE, SEARCH_TOUR } from "../content/tours/redux/constants";
import { COMMON_VALIDATION_ERROR, COMMON_ERROR, COMMON_REQUEST, COMMON_SUCCESS, LOGIN, USER_DATA, LOGOUT_SUCCESS, IS_DARK_MODE, LIST_DATA, BOOK_DATA, SEARCH_DATA, SIGNUP, EXCHANGE_RATES } from "./constants";
import storage from 'redux-persist/lib/storage';


const initialState = {
    list_data: null,
    book_data: null,
    searchValue: null,
    date_range: null,
    last_page: 1,
    exchange_rates: null,
    common_error: null,
    common_success: null,
    common_validation_error: null,
    common_requesting: false,
    is_dark_mode: false,
    user: false,
    isLogged: false,
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        /** 
         * @param COMMON_ERROR - usage for snackbar when post request given error
         */
        case COMMON_ERROR:
            return { ...state, common_error: action.data };


        /** 
        * @param COMMON_SUCCESS - usage for snackbar when post request given success
        */
        case COMMON_SUCCESS:
            return { ...state, common_success: action.data };


        /** 
        * @param COMMON_VALIDATION_ERROR - usage for form validation error which one sended from api
        */
        case COMMON_VALIDATION_ERROR:
            return { ...state, common_validation_error: action.data };


        /** 
        * @param COMMON_REQUEST - usage for set new state - to request controlling state
        */
        case COMMON_REQUEST:
            return { ...state, common_requesting: action.data };

        /**
         * @param IS_DARK_MODE - usage for toggling dark mode state
         */
        case IS_DARK_MODE:
            return { ...state, is_dark_mode: action.data };

        /**
         * @param EXCHANGE_RATES - usage for get exchange rates
         */
        case EXCHANGE_RATES:
            return { ...state, exchange_rates: action.data };

        /**
         * @param LOGIN - usage for setting state when a login action is performed
         * @param SEARCH_TOUR - usage for setting state when a tour search action is performed
         * @param BOOK_TOUR - usage for setting state when a tour booking action is performed
         */
        case SIGNUP:
        case GET_TOURS:
        case LOGIN:
        case SEARCH_TOUR:
        case BOOK_TOUR:
        case GET_TOUR_BY_CODE:
            return {
                ...state,
                common_requesting: true,
            };

        /**
         * @param LOGOUT_SUCCESS - usage for setting state when logout is successful
         */
        case LOGOUT_SUCCESS:
            storage.removeItem('persist:adali');
            // Cookies.remove('twj');
            return {
                ...state,
                ...initialState,
            };

        /**
         * @param USER_DATA - usage for updating user data in the state
         */
        case USER_DATA:
            return {
                ...state,
                user: action.data,
                isLogged: action.data.username,
            };

        /**
         * @param LIST_DATA - usage for updating list_data data in the state
         */
        case LIST_DATA:
            return {
                ...state,
                list_data: action.data,
            };
        /**
         * @param BOOK_DATA - usage for updating list_data data in the state
         */
        case BOOK_DATA:
            return {
                ...state,
                book_data: action.data,
            };

        /**
        * @param SEARCH_DATA - usage for updating searchValue and date_range in the state
        */
        case SEARCH_DATA:
            return {
                ...state,
                searchValue: action.data.searchValue,
                date_range: action.data.date_range,
                last_page: action.data.last_page
            };

        default:
            return state;
    }
};

export default commonReducer;
