import { authToken, instance, setParams } from "../utils/xhr";
import { CATEGORY_URL } from "./constants";


export const getCategories = async (queryParams) => {
    return await instance.get(CATEGORY_URL, setParams({ ...queryParams }));
};

export const getCategoryByName = async (name) => {
    return await instance.get(`${CATEGORY_URL}/detail`, setParams({ name }));
};
