import { authToken, instance, setParams } from "../utils/xhr";
import { LOGIN, SIGNUP } from "./constants";

export const login = async (body) => {
    return await instance.post(`${LOGIN}`, body);
};

export const signUp = async (body) => {
    return await instance.post(`${SIGNUP}`, body);
};


export const logout = async (token) => {
    return await instance.get(`/logout`, setParams("", token));
};