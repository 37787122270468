// src/pages/content/categories/redux/actions.js
import { CATEGORY_DATA, GET_CATEGORIES } from "./constants";

export const getCategoriesAction = (queryParams) => ({
    type: GET_CATEGORIES,
    queryParams
});

export const setCategoryData = (data) => ({
    type: CATEGORY_DATA,
    data
});