import moment from 'moment/min/moment-with-locales';
import i18n from "i18next";
import { EURO, GBP, TL, USD } from './constants';
import { useCallback } from 'react';
const { format, parse, startOfMonth, endOfMonth, addYears } = require('date-fns');
const { tr, enUS } = require('date-fns/locale');

export const getStorageItem = (itemName) => {
    const item = localStorage.getItem(itemName);
    return item ? JSON.parse(item) : false;
}
export const setStorageItem = (itemName, itemValue) => {
    if (Boolean(itemName) && Boolean(itemValue)) {
        localStorage.setItem(itemName, JSON.stringify(itemValue))
    }
}

export const replaceFirstToUpperCase = (value) => {
    return value?.replace(value.charAt(0), value.charAt(0).toUpperCase());
}

export const getMainPathname = (pathname) => {
    const parts = pathname.split('/');
    if (parts?.length > 0) {
        return parts[1];
    } else {
        return "";
    }
}

export const getToday = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset();
    now.setMinutes(now.getMinutes() - offset + 5); // Adjust for timezone offset
    return now.toISOString().slice(0, 16);
}

export const getDateFormat = (date) => {
    // Пробуем разобрать строку даты, указывая несколько форматов
    const parsedDate = moment(date, ["YYYY-MM-DD", "DD.MM.YYYY", moment.ISO_8601], true);

    // Проверяем, является ли дата валидной
    if (parsedDate.isValid()) {
        // Возвращаем дату в нужном формате
        return parsedDate.format("DD.MM.YYYY");
    } else {
        // Если дата не валидна, возвращаем ошибку или пустую строку
        console.error('Invalid date format');
        return '';
    }
}

export const getPriceType = (value) => {
    switch (value) {
        case "TRY":
            return TL;
        case "EUR":
            return EURO;
        case "USD":
            return USD;
        case "GBP":
            return GBP;
        default:
            return "";
    }
}


export const generateTourDates = () => {
    const monthNames = [
        i18n.t("calendar.january"), i18n.t("calendar.february"), i18n.t("calendar.march"),
        i18n.t("calendar.april"), i18n.t("calendar.may"), i18n.t("calendar.june"),
        i18n.t("calendar.july"), i18n.t("calendar.august"), i18n.t("calendar.september"),
        i18n.t("calendar.october"), i18n.t("calendar.november"), i18n.t("calendar.december")
    ];

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const tourDates = [];

    for (let i = 0; i < 12; i++) {
        const monthIndex = (currentMonth + i) % 12;
        const year = currentYear + Math.floor((currentMonth + i) / 12);
        tourDates.push({ id: i + 1, name: `${monthNames[monthIndex]} ${year}` });
    }
    tourDates.unshift({ id: tourDates.length + 1, name: i18n.t("common.doesn't_matter") })
    return tourDates;
};


export function parseMonthYear(dateString) {
    const today = new Date();
    if (dateString?.toLowerCase() === "doesn't matter" || dateString?.toLowerCase() === "farketmez") {
        const start = today;
        const end = addYears(today, 1);
        return [start.toISOString(), end.toISOString()]; // Return ISO strings
    }

    const [monthStr, yearStr] = dateString.split(' ');
    const year = parseInt(yearStr, 10);

    let month;
    let curLang = getStorageItem("lang")?.value ?? "tr";

    // Check the language and parse accordingly
    if (curLang === "en") { // English
        const date = parse(`1 ${monthStr} ${year}`, 'd MMMM yyyy', new Date(), { locale: enUS });
        month = date.getMonth();
    } else if (curLang === "tr") { // Turkish
        const date = parse(`1 ${monthStr} ${year}`, 'd MMMM yyyy', new Date(), { locale: tr });
        month = date.getMonth();
    }

    if (month === undefined || isNaN(month)) {
        return "";
    }

    const start = startOfMonth(new Date(year, month));
    const end = endOfMonth(new Date(year, month));

    return [start.toISOString(), end.toISOString()]; // Return ISO strings
}

export function parseMonthYearArray(dateStrings) {
    const today = new Date();
    const results = [];

    dateStrings.forEach(dateString => {
        if (dateString?.toLowerCase() === "doesn't matter" || dateString?.toLowerCase() === "farketmez") {
            const start = today;
            const end = addYears(today, 1);
            results.push([start.toISOString(), end.toISOString()]);
        } else {
            const [monthStr, yearStr] = dateString.split(' ');
            const year = parseInt(yearStr, 10);

            let month;
            let curLang = getStorageItem("lang")?.value ?? "tr";

            // Check the language and parse accordingly
            if (curLang === "en") { // English
                const date = parse(`1 ${monthStr} ${year}`, 'd MMMM yyyy', new Date(), { locale: enUS });
                month = date.getMonth();
            } else if (curLang === "tr") { // Turkish
                const date = parse(`1 ${monthStr} ${year}`, 'd MMMM yyyy', new Date(), { locale: tr });
                month = date.getMonth();
            }

            if (month === undefined || isNaN(month)) {
                return;
            }

            const start = startOfMonth(new Date(year, month));
            const end = endOfMonth(new Date(year, month));
            results.push([start.toISOString(), end.toISOString()]);
        }
    });

    return results;
}

export const getPricesOfTourDate = (isLogged, user, tourDate, hotelType) => {
    if (!tourDate?.datePrices?.length > 0) return {};

    // Find the price entry that matches the selected hotel type
    let datePrice = tourDate.datePrices.find(dp => dp.hotels_type === hotelType) || tourDate.datePrices[0];

    let totalAmount = datePrice.adult_price;

    // Check if the user is logged in
    if (isLogged && user) {
        // If user is logged in, apply the to_members_discount
        if (Boolean(datePrice.to_members_discount)) {
            totalAmount -= (datePrice.adult_price * datePrice.to_members_discount) / 100;
        }
    } else {
        // If user is not logged in, apply the regular discount
        if (Boolean(datePrice.discount)) {
            totalAmount -= (datePrice.adult_price * datePrice.discount) / 100;
        }
    }

    return { ...datePrice, total_amount: datePrice.adult_price === totalAmount ? 0 : totalAmount }
}

export const placeHandler = (value) => {
    return value ? value.replaceAll("->", " - ") : "";
};