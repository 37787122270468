import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CustomProvider } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './App.scss';
import { getStorageItem } from './utils/helpers';
import * as locales from 'rsuite/locales';
import 'react-photo-view/dist/react-photo-view.css';
import Gateway from './pages/Gateway';

const data = Object.keys(locales).map(key => ({
  key,
  value: locales[key]
}));


function App() {
  const [isDarkMode, setIsDarkMode] = useState(getStorageItem('is_dark_mode'));
  const [localeKey, setLocaleKey] = useState('trTR');
  const locale = data.find(item => item.key === localeKey);

  useEffect(() => {
    const currLang = getStorageItem("lang")?.value;

    if (currLang) {
      setLocaleKey(currLang === "tr" ? "trTR" : "enUS");
    }
  }, []);

  // const theme = {
  //   theme: isDarkMode ? 'dark' : 'light',
  //   Button: {
  //     primary: {
  //       color: '#fff',
  //       fontFamily: 'PlusJakartaText',
  //       background: '#AE8EF1',
  //       borderColor: '#AE8EF1',
  //     },
  //   },
  //   Text: {
  //     fontFamily: 'PlusJakartaText',
  //     textTransform: 'none',
  //   }
  //   // Добавьте остальные стили здесь
  // };

  return (
    <Router basename={'/'}>
      <CustomProvider theme={isDarkMode ? 'dark' : 'light'} locale={locale.value}>
        <Gateway />
      </CustomProvider>
    </Router>
  );
}

export default App;