import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files

import translationEN from './locales/en/translation.json';
import translationTR from './locales/tr/translation.json';
import { getStorageItem } from './utils/helpers';
//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN
  },
  tr: {
    translation: translationTR
  }
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: getStorageItem("lang")?.value ?? 'tr', //default language
  //keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
