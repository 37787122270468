import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';


//check user logged or not
const AuthProvider = () => {
    const isLogged = useSelector(state => state.commonReducer.isLogged);
    const twj = Cookies.get("twj");

    return (
        (isLogged && twj) ? <Navigate to="/" /> : <Outlet />
    )
}

export default AuthProvider