
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_DATA = "USER_DATA";
export const COMMON_ERROR = "COMMON_ERROR"
export const COMMON_SUCCESS = "COMMON_SUCCESS"
export const COMMON_VALIDATION_ERROR = "COMMON_VALIDATION_ERROR"
export const COMMON_REQUEST = "COMMON_REQUEST"
export const IS_DARK_MODE = "IS_DARK_MODE";

export const EXCHANGE_RATES = "EXCHANGE_RATES";
export const LIST_DATA = "LIST_DATA";
export const BOOK_DATA = "BOOK_DATA";
export const SEARCH_DATA = "SEARCH_DATA";
