// src/pages/content/banners/redux/reducer.js
import {
    BANNER_DATA,
    GET_BANNER_BY_CODE,
} from "./constants";

const initialState = {
    banner_data: [],
};

const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        /**
         * @param BANNER_DATA - usage for updating banner_data data in the state
         */
        case BANNER_DATA:
            return {
                ...state,
                banner_data: action.data,
            };
        default:
            return state;
    }
};

export default bannerReducer;
