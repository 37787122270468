import { instance, setParams, } from "../utils/xhr";
import { BANNER_URL } from "./constants";


export const getBanners = async (queryParams) => {
    return await instance.get(BANNER_URL, setParams({ ...queryParams }));
};

export const getActiveBanner = async () => {
    return await instance.get(`${BANNER_URL}/active`);
};
