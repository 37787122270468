import { BANNER_DATA, GET_ACTIVE_BANNER, GET_BANNERS } from "./constants";

export const getBannersAction = (queryParams) => ({
    type: GET_BANNERS,
    queryParams
});

export const getActiveBannerAction = (callback) => ({
    type: GET_ACTIVE_BANNER,
    callback
});


export const setBannerData = (data) => ({
    type: BANNER_DATA,
    data
});